import { transmissionTypeEmail } from "./TransmissionTypes";
import * as OrderServices from '@/backoffice/modules/purchase/services/order/order.services';
import i18n from "@/i18n";
import prompts from '@/core/tools/notifications/notifications';

export default {
    [transmissionTypeEmail]: async transmissionData => {
         try {
            await OrderServices.sendOrderByEmailToVendor({
                to: [transmissionData.to],
                bcc: transmissionData.bcc && [transmissionData.bcc] || [],
                orderId: transmissionData.orderId,
                language: transmissionData.language,
            });    
        }
        catch(e) {
            await prompts.error({
                text: i18n.t('Purchase.Order.SendOrderTemplate.Messages.SendEmailError')
            });
            return false;
        }
        return true;
    }
}