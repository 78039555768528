import * as OrderServices from '@/backoffice/modules/purchase/services/order/order.services';
import { receivingServices } from '@/backoffice/modules/purchase/services/receiving/receiving.services';
import { OrderGridTabs } from '@/backoffice/modules/purchase/pages/orders/order/orderGridTabs.js';
import { ReceivingStatus } from '../domain/purchase';
import { ApiError } from '@/core/services/_api.config';

const getDefaultState = () => {
  return {
    order: null,
    orders: [],
    orderCurrentBody: null,
    orderGridTabIndex: OrderGridTabs.OrderBodiesTabIndex,
    orderRelatedReceivingBodies: []
  }
}

export const Order = {
  namespaced: true,
  name: 'Order',
  state: getDefaultState(),
  getters: {
    getOrder: state => {
      return state.order;
    },    
    getOrders: state => {
      return state.orders || [];
    },
    getOrderGridTabIndex: state => {
      return state.orderGridTabIndex;
    },
    getOrderRelatedReceivingBodies: state => {
      return state.orderRelatedReceivingBodies
    },
    getOrderReceivedInfos: state => {
      const receivedInfo = {}
      state.orderRelatedReceivingBodies.forEach(i => {
        const key = i.orderBodyID

        if(!receivedInfo[key]) {
          receivedInfo[key] = Object.assign({}, { qty: i.qty, cost: i.cost });
        } 
        else {
          receivedInfo[key].qty += i.qty;
          receivedInfo[key].cost += i.cost;
        }
      });
      return receivedInfo
    }
  },

  mutations: {
    RESET_STORE(state){
      Object.assign(state, getDefaultState());
    },

    SET_ORDERS(state, orders){
      state.orders = orders;
    },

    SET_ORDER(state, order) {
      state.order = order;
    },

    SET_ORDER_CURRENT_BODY(state, orderCurrentBody) {
      state.orderCurrentBody = orderCurrentBody;
    },

    SET_ORDER_GRID_TAB_INDEX(state, orderGridTabIndex) {
      state.orderGridTabIndex = orderGridTabIndex;
    },

    SET_ORDER_RELATED_RECEIVING_BODIES(state, orderRelatedReceivingBodies) {
      state.orderRelatedReceivingBodies = orderRelatedReceivingBodies;
    },
    RESET_ORDERS(state){
      state.orders = []
    }
  },

  actions: {    
    async fetchOrder({ commit }, id) {
      const response = await OrderServices.getOrder(id);
      if(response.status >= 200 && response.status <= 299) {
        const order = response.data[0];
        commit('SET_ORDER', order);
      }
      else {
        commit('SET_ORDER', null);
        throw response;
      }
    },

    async fetchOrders({ commit, state }) {
      try {
        const data = await OrderServices.getOrders()
        commit('SET_ORDERS', data)
      } catch (error) {
        if (error.message !== ApiError.OPERATION_CANCELED)
          throw error
      }
    },

    async fetchOrdersByVendorName({ commit }, vendorName){
      const response = await OrderServices.getOrdersByVendorName(vendorName);
  
      if(response.status >= 200 && response.status <= 299) {
        return response.data;
      }
      else {
        throw response;
      }
    },

    async fetchOrdersByNo({ commit }, orderNo){
      const parsedOrderNo = parseInt(orderNo);
      if (isNaN(parsedOrderNo)) { return; }

      const response = await OrderServices.getOrdersByNo(parsedOrderNo);
  
      if(response.status >= 200 && response.status <= 299) {
        return response.data;
      }
      else {
        throw response;
      }
    },

    async fetchOrdersByIds({ commit }, ids){
      const response = await OrderServices.getOrdersByIds(ids);
  
      if(response.status >= 200 && response.status <= 299) {
        return response.data;
      }
      else {
        throw response;
      }
    },

    async fetchOrdersByVendorCode({ commit }, vendorCode){
      const response = await OrderServices.getOrdersByVendorCode(vendorCode);
  
      if(response.status >= 200 && response.status <= 299) {
        return response.data;
      }
      else {
        throw response;
      }
    },

    async saveOrder({ commit }, order) {
      let response = null;

      if (!order.id) {
        response = await OrderServices.addOrders([order]);
      }
      else {
        response = await OrderServices.updateOrders([order]);
      }
  
      if (response.status == 200) {
        commit('SET_ORDER', response.data[0]);
      }
      else {
        throw response;
      }
    },

    async updateOrders({ commit }, orders) {
      const response = await OrderServices.updateOrders(orders);
  
      if (response.status != 200) {
        throw response;
      }
    },

    async fetchOrderRelatedReceivingBodies({ commit }, orderId) {
      const params = {
        OrderIds: [orderId],
        ReceivingStatus: ReceivingStatus.VOID,
        ReceivingOperator: "!="
      };

      const response = await receivingServices.getReceivings(params);

      if (response.status == 200) {
        commit("SET_ORDER_RELATED_RECEIVING_BODIES", response.data);
      } else {
        commit("SET_ORDER_RELATED_RECEIVING_BODIES", []);
        throw response;
      }
    },

    async fetchTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions({ commit }){
      const response = await OrderServices.getTargetLayerPartiallyReceivedAndPendingOrdersByNotOpenReceptions();
  
      if(response.status >= 200 && response.status <= 299) {
        commit('SET_ORDERS',  response.data);
      }
      else {
        commit('SET_ORDERS', []);
        throw response;
      }
    }
  }
}
