import axios from 'axios';
import vuex from '@/core/store';
export default (token = vuex.getters['Account/getToken']) => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT || '',
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Accept-Language': vuex.getters['App/getLanguage'] || navigator.languages
    }
  });
};


export const ApiBlob = (token = vuex.getters['Account/getToken']) => {
  return axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT || '',
    withCredentials: false,
    headers: {
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
      Authorization: `Bearer ${token}`,
      'Accept-Language': vuex.getters['App/getLanguage'] || navigator.languages
    }
  });
};

const axiosCancelToken = axios.CancelToken
export function getNewCancelToken() {
  return axiosCancelToken.source()
}

export const ApiError = {
  OPERATION_CANCELED: 'OPERATION_CANCELED'
}